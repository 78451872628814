interface Research {
    successRate: number
    notSmokedFor4Weeks: number
    successRateStrategy: number
    ts: Date
    users: number
}
export const  smoxyResearchData = () => {
    return <Research>{
        successRate: 75.58,
        notSmokedFor4Weeks: 92.11,
        successRateStrategy: 93.70,
        ts: new Date(2022, 6),
        users: 37053
    }
};